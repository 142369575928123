"use client";

import React, { useRef, useEffect } from "react";
import { PAGE_TYPE } from "@/common-components-src/js/server";
import { JDIHeaderNew, JDIFooterNew, JDIHeaderNewMethods, JDIFloatingToolbar, parseCookieString } from "@/common-components-src/js/main";
import { isNextServer } from "@/utils/uaParser";

interface Props {
  children: React.ReactNode;
  pageType: PAGE_TYPE;
}

export const HeaderFooterWrapper: React.FC<Props> = ({ children, pageType }) => {
  // eslint-disable-next-line react/display-name
  const headerRef = useRef<JDIHeaderNewMethods | null>(null);
  const isServer = isNextServer();
  let userInfo: any = null;

  if (isServer) {
    const { cookies } = require("next/headers");
    const cookieStr = cookies().toString();
    const cookiesObj = parseCookieString(cookieStr);
    userInfo = { pin: cookiesObj?.mfs_session };
  } else {
    const cookieStr = document.cookie;
    const cookiesObj = parseCookieString(cookieStr);
    userInfo = { pin: cookiesObj?.mfs_session };
  }

  useEffect(() => {
    if (headerRef.current) {
      (window as any).JDIheader = headerRef.current;
    }
  }, []);

  return (
    <>
      <div style={{ position: "relative", zIndex: 1000 }}>
        <JDIHeaderNew ref={headerRef} pageType={pageType} userInfo={userInfo} />
      </div>
      {children}
      <JDIFooterNew />
      <JDIFloatingToolbar />
    </>
  );
};

export default HeaderFooterWrapper;
